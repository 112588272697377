// data.json
export const slideImageData = [
  {
    imageUrl: "https://www.motherteresacollege.net/images/banner2.jpg",
    altText: "Image 1"
  },
  {
    imageUrl: "https://www.motherteresacollege.net/images/banner5.jpg",
    altText: "Image 2"
  },
  {
    imageUrl: "https://www.motherteresacollege.net/images/banner4.jpg",
    altText: "Image 3"
  },
  {
    imageUrl: "https://www.motherteresacollege.net/images/banner3.jpg",
    altText: "Image 3"
  }
];
