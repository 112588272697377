// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendarContainer {
  overflow-x: auto;
  /* background-size: cover; */
  padding: 10px;
  /* background-image: url("../../../img/bg.avif"); */
  /* height: 85vh; */
}

.calendarContainer .calendarContent {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.calendarContainer .sidebar {
  flex: 1 1 20%;
  padding: 15px;
  border-radius: 4px;
  background: none;
  height: auto;
}

.calendarContainer .sidebar h5 {
  margin-bottom: 10px;
  color: black;
}

.calendarContainer .calendar {
  flex: 1 1 100%;
}

.calendarContainer ul {
  list-style-type: none;
  padding: 0;
}

.calendarContainer .eventItem {
  background-color: rgb(244, 201, 83);
  margin: 10px 0;
  border-radius: 2px;
  padding: 5px;
  font-size: 10px;
  text-align: center;
  color: black;
}

.calendarContainer .eventItem:hover {
  background-color: #a2a6ac;
}

.calendarContainer .eventTitle {
  font-weight: bold;
}

.calendarContainer .eventDate {
  display: block;
  font-size: 0.8rem;
  color: #ffffff;
}

.fc-day {
  background-color: #a2a6ac;
}

.fc-daygrid-bg-harness,
.fc-highlight {
  background-color: #74777c;
}
`, "",{"version":3,"sources":["webpack://./src/pages/StudentDashboard/Calendar/StudentCalendar.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,4BAA4B;EAC5B,aAAa;EACb,mDAAmD;EACnD,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,aAAa;EACb,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,mCAAmC;EACnC,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,yBAAyB;AAC3B","sourcesContent":[".calendarContainer {\n  overflow-x: auto;\n  /* background-size: cover; */\n  padding: 10px;\n  /* background-image: url(\"../../../img/bg.avif\"); */\n  /* height: 85vh; */\n}\n\n.calendarContainer .calendarContent {\n  display: flex;\n  justify-content: space-between;\n  padding: 20px;\n}\n\n.calendarContainer .sidebar {\n  flex: 1 1 20%;\n  padding: 15px;\n  border-radius: 4px;\n  background: none;\n  height: auto;\n}\n\n.calendarContainer .sidebar h5 {\n  margin-bottom: 10px;\n  color: black;\n}\n\n.calendarContainer .calendar {\n  flex: 1 1 100%;\n}\n\n.calendarContainer ul {\n  list-style-type: none;\n  padding: 0;\n}\n\n.calendarContainer .eventItem {\n  background-color: rgb(244, 201, 83);\n  margin: 10px 0;\n  border-radius: 2px;\n  padding: 5px;\n  font-size: 10px;\n  text-align: center;\n  color: black;\n}\n\n.calendarContainer .eventItem:hover {\n  background-color: #a2a6ac;\n}\n\n.calendarContainer .eventTitle {\n  font-weight: bold;\n}\n\n.calendarContainer .eventDate {\n  display: block;\n  font-size: 0.8rem;\n  color: #ffffff;\n}\n\n.fc-day {\n  background-color: #a2a6ac;\n}\n\n.fc-daygrid-bg-harness,\n.fc-highlight {\n  background-color: #74777c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
