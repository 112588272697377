// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerDashboard {
  display: flex;
  /* border: 1px solid red; */
  height: 100vh;
}

.containerDashboard .content {
  flex: 1 1;
  overflow-x: hidden;
}

.containerDashboard .navbar {
  flex-shrink: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/AllRoutes/AllRoutes.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,aAAa;AACf;;AAEA;EACE,SAAO;EACP,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".containerDashboard {\n  display: flex;\n  /* border: 1px solid red; */\n  height: 100vh;\n}\n\n.containerDashboard .content {\n  flex: 1;\n  overflow-x: hidden;\n}\n\n.containerDashboard .navbar {\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
