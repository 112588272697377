// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notificationTeacherContainer {
  /* background-size: cover; */
  padding: 20px;
  /* background-image: url("../../../img/bg.avif"); */
  /* height: 90vh; */
}

.notificationTeacherContainer .headingText {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #3b3b3b;
}

.notificationTeacherContainer .cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.notificationTeacherContainer .card {
  transition: transform 0.3s ease;
  width: 300px;
  padding: 1.5rem;
  color: #3b3b3b;
  border: 1px solid #bbb;
  border-radius: 0.75rem;
  /* background-color: rgba(255, 255, 255, 0.6); */
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  background-clip: padding-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.notificationTeacherContainer .card:hover {
  transform: translateY(-10px);
}

.notificationTeacherContainer .card h2 {
  margin-top: 0;
  font-size: 1.3rem;
  color: #555;
}

.notificationTeacherContainer .card p {
  margin-bottom: 10px;
  color: #666;
}

.notificationTeacherContainer .card p:last-child {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/TeacherDashboard/Notification/TeacherNotification.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,aAAa;EACb,mDAAmD;EACnD,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE,+BAA+B;EAC/B,YAAY;EACZ,eAAe;EACf,cAAc;EACd,sBAAsB;EACtB,sBAAsB;EACtB,gDAAgD;EAChD,kCAA0B;UAA1B,0BAA0B;EAC1B,4BAA4B;EAC5B,wCAAwC;AAC1C;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".notificationTeacherContainer {\n  /* background-size: cover; */\n  padding: 20px;\n  /* background-image: url(\"../../../img/bg.avif\"); */\n  /* height: 90vh; */\n}\n\n.notificationTeacherContainer .headingText {\n  text-align: center;\n  margin-bottom: 20px;\n  font-size: 2rem;\n  color: #3b3b3b;\n}\n\n.notificationTeacherContainer .cardContainer {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 20px;\n  justify-content: center;\n}\n\n.notificationTeacherContainer .card {\n  transition: transform 0.3s ease;\n  width: 300px;\n  padding: 1.5rem;\n  color: #3b3b3b;\n  border: 1px solid #bbb;\n  border-radius: 0.75rem;\n  /* background-color: rgba(255, 255, 255, 0.6); */\n  backdrop-filter: blur(2px);\n  background-clip: padding-box;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.notificationTeacherContainer .card:hover {\n  transform: translateY(-10px);\n}\n\n.notificationTeacherContainer .card h2 {\n  margin-top: 0;\n  font-size: 1.3rem;\n  color: #555;\n}\n\n.notificationTeacherContainer .card p {\n  margin-bottom: 10px;\n  color: #666;\n}\n\n.notificationTeacherContainer .card p:last-child {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
