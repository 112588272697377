// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Question Paper Page
.question-paper-page {
  padding: 20px;
} */

/* Add Question Paper Button */
/* .add-question-paper-btn {
  background-color: #3e3e45;
  color: rgb(158, 156, 156);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.add-question-paper-btn:hover {
  color: #fff;
} */

/* Add Question Paper Form */
/* .add-question-paper-form {
  margin-bottom: 20px;
}

.form-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #218838;
} */

/* Filter Section */
/* .filter-section {
  margin-bottom: 20px;
}

.filter-label {
  margin-right: 10px;
}

.filter-select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.filter-btn {
  background-color: #ffc107;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.filter-btn:hover {
  background-color: #e0a800;
} */

/* Question Paper List */
/* .question-paper-list-title {
  margin-bottom: 10px;
}

.card {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
}

.card p {
  margin: 5px 0;
}

.card a {
  text-decoration: none;
  color: #007bff;
}

.card a:hover {
  text-decoration: underline;
} */
`, "",{"version":3,"sources":["webpack://./src/pages/TeacherDashboard/NewQuestion/TeacherNewQuestion.css"],"names":[],"mappings":"AAAA;;;GAGG;;AAEH,8BAA8B;AAC9B;;;;;;;;;;;;GAYG;;AAEH,4BAA4B;AAC5B;;;;;;;;;;;;;;;;;;;;;;;GAuBG;;AAEH,mBAAmB;AACnB;;;;;;;;;;;;;;;;;;;;;;;;;;GA0BG;;AAEH,wBAAwB;AACxB;;;;;;;;;;;;;;;;;;;;;;;GAuBG","sourcesContent":["/* Question Paper Page\n.question-paper-page {\n  padding: 20px;\n} */\n\n/* Add Question Paper Button */\n/* .add-question-paper-btn {\n  background-color: #3e3e45;\n  color: rgb(158, 156, 156);\n  border: none;\n  padding: 10px 20px;\n  border-radius: 5px;\n  margin-bottom: 20px;\n  cursor: pointer;\n}\n\n.add-question-paper-btn:hover {\n  color: #fff;\n} */\n\n/* Add Question Paper Form */\n/* .add-question-paper-form {\n  margin-bottom: 20px;\n}\n\n.form-input {\n  width: 100%;\n  padding: 10px;\n  margin-bottom: 10px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n}\n\n.submit-btn {\n  background-color: #28a745;\n  color: white;\n  border: none;\n  padding: 10px 20px;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.submit-btn:hover {\n  background-color: #218838;\n} */\n\n/* Filter Section */\n/* .filter-section {\n  margin-bottom: 20px;\n}\n\n.filter-label {\n  margin-right: 10px;\n}\n\n.filter-select {\n  padding: 8px;\n  border-radius: 5px;\n  border: 1px solid #ccc;\n  margin-right: 10px;\n}\n\n.filter-btn {\n  background-color: #ffc107;\n  color: black;\n  border: none;\n  padding: 10px 20px;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.filter-btn:hover {\n  background-color: #e0a800;\n} */\n\n/* Question Paper List */\n/* .question-paper-list-title {\n  margin-bottom: 10px;\n}\n\n.card {\n  background-color: #f8f9fa;\n  border: 1px solid #dee2e6;\n  border-radius: 5px;\n  padding: 15px;\n  margin-bottom: 15px;\n}\n\n.card p {\n  margin: 5px 0;\n}\n\n.card a {\n  text-decoration: none;\n  color: #007bff;\n}\n\n.card a:hover {\n  text-decoration: underline;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
